<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <div class="row">
            <!-- Per Page -->
            <div
              class="col-lg-3 col-md-3 col-sm-12 d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label for="perPage">Show</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-2"
                id="perPage"
              ></v-select>
              <label for="perPage" class="ml-2">entries</label>
            </div>

            <div
              class="col-lg-4 col-md-4 col-sm-12 d-flex align-items-start justify-content-start"
            >
              <flat-pickr
                placeholder="Date Range"
                v-model="rangeDate"
                class="form-control"
                :config="config"
              ></flat-pickr>
              <b-button @click="clearDate()" class="ml-1" variant="success"
                >Clear</b-button
              >
            </div>

            <div
              class="col-lg-3 col-md-3 col-sm-12 d-flex align-items-center justify-content-start"
            >
              <!-- Your fourth element goes here -->
            </div>

            <div
              class="col-lg-2 col-md-2 col-sm-12 d-flex align-items-end justify-content-end"
            >
              <b-button
                variant="primary"
                v-if="$Can('lead_create')"
                href="/scopes/scope-add"
              >
                <span class="text-nowrap">Add Scope</span>
              </b-button>
            </div>
          </div>
        </div>

        <b-table
          striped
          hover
          ref="refUserListTable"
          class="position-relative"
          :style="totalRows < 3 ? 'height: 500px' : ''"
          :items="fetchLeads"
          :filter="filter"
          filter-debounce="250"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
          @row-selected="onRowSelected"
          selectable
          select-mode="single"
          responsive
          :small="true"
          :fields="leadHeaders"
          show-empty
          empty-text="No matching records found"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <!-- Column: Id -->
          <!-- Column: Id -->
          <!-- <template #cell(id)="data">
            <strong class="text-primary">#{{ Number(data.value) + 100000 }}</strong>
          </template> -->

          <!-- Column: id -->
          <template #cell(id)="data">
            <strong class="text-primary"
              >#{{ Number(data.item.id ? data.item.id : 0) }}</strong
            >
          </template>
          <!-- Column: firstName -->
          <template #cell(firstName)="data">
            {{ data.item.firstName ? data.item.firstName : "N/A" }}
          </template>
          <!-- Column: lastName -->
          <template #cell(lastName)="data">
            {{ data.item.lastName ? data.item.lastName : "N/A" }}</template
          >
          <!-- Column: email -->
          <template #cell(email)="data">
            {{ data.item.email ? data.item.email : "N/A" }}
          </template>
          <!-- Column: phoneNumber -->
          <template #cell(phoneNumber)="data">
            {{ data.item.phoneNumber ? data.item.phoneNumber : "N/A" }}
          </template>

          <template #cell(quote)="data">
            {{ data.item.quote ? data.item.quote : "N/A" }}
          </template>

          <template #cell(responseDate)="data">
            {{ data.item.responseDate ? data.item.responseDate : "N/A" }}
          </template>

          <template #cell(responseMethod)="data">
            {{ data.item.responseMethod ? data.item.responseMethod : "N/A" }}
          </template>

          <template #cell(note)="data">
            {{ data.item.note ? data.item.note : "N/A" }}
          </template>

          <template #cell(solution)="data">
            {{ data.item.solution ? data.item.solution : "N/A" }}
          </template>

          <!-- Column: Source -->

          <!-- Column: phoneNumber -->

          <template #cell(user)="data">
            <span v-if="data.item.userId != '0'">{{
              data.item.user ? data.item.user : "NOT ASSIGNED"
            }}</span>
            <span v-if="data.item.userId == '0'"> ALL USERS </span>
          </template>
          <!-- Column: Source -->

          <template #cell(source)="data">
            <b-badge
              v-if="
                data.item.source == 'website' || data.item.source == 'WEBSITE'
              "
              pill
              variant="light-primary"
              class="badge-glow"
              >Website</b-badge
            >
            <b-badge
              v-if="data.item.source == 'user' || data.item.source == 'USER'"
              pill
              variant="light-warning"
              class="badge-glow"
              >User</b-badge
            >
          </template>

          <template #cell(channel)="data">
            <b-badge v-if="data.item.channel == null"> N/A </b-badge>
            <b-badge
              v-if="data.item.channel == 'INSTAGRAM'"
              pill
              class="bg-insta"
              >{{ data.item.channel }}</b-badge
            >
            <b-badge
              v-if="data.item.channel == 'FACEBOOK'"
              pill
              class="bg-facebook"
              >{{ data.item.channel }}</b-badge
            >
            <b-badge
              v-if="data.item.channel == 'TWITTER'"
              pill
              class="bg-twitter"
              >{{ data.item.channel }}</b-badge
            >
            <b-badge
              v-if="data.item.channel == 'YOUTUBE'"
              pill
              class="bg-youtube"
              >{{ data.item.channel }}</b-badge
            >
            <b-badge
              v-if="data.item.channel == 'LINKED IN'"
              pill
              class="bg-linked"
              >{{ data.item.channel }}</b-badge
            >
            <b-badge
              v-if="data.item.channel == 'SYSTEMS TAILOR MADE'"
              pill
              class="bg-systems"
              >{{ data.item.channel }}</b-badge
            >

            <b-badge
              v-if="
                data.item.channel != 'SYSTEMS TAILOR MADE' &&
                  data.item.channel != 'INSTAGRAM' &&
                  data.item.channel != 'FACEBOOK' &&
                  data.item.channel != 'TWITTER' &&
                  data.item.channel != 'YOUTUBE' &&
                  data.item.channel != 'LINKED IN'
              "
              pill
              variant="warning"
              >{{ data.item.channel }}</b-badge
            >
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              v-if="data.item.status == 'A'"
              pill
              variant="secondary"
              class="badge-glow"
              >Draft</b-badge
            >
            <b-badge
              v-if="data.item.status == 'B'"
              pill
              variant="warning"
              class="badge-glow"
              >Approved</b-badge
            >
            <b-badge
              v-if="data.item.status == 'C'"
              pill
              variant="primary"
              class="badge-glow"
              >In Progress</b-badge
            >
            <b-badge
              v-if="data.item.status == 'D'"
              pill
              variant="success"
              class="badge-glow"
              >Completed</b-badge
            >
          </template>

          <template #cell(reason)="data">
            <b-badge
              v-if="data.item.reason == 'GENERAL ENQUIRY'"
              pill
              variant="primary"
              class="badge-glow"
              >GENERAL ENQUIRY</b-badge
            >
            <b-badge
              v-if="data.item.reason == 'VBA PROJECT'"
              pill
              variant="success"
              class="badge-glow"
              >VBA PROJECT
            </b-badge>
            <b-badge
              v-if="data.item.reason == 'WEB PROJECT'"
              pill
              variant="info"
              class="badge-glow"
              >WEB PROJECT
            </b-badge>
          </template>

          <template #cell(method)="data">
            <b-badge
              v-if="data.item.method == 'SMS'"
              pill
              variant="success"
              class="badge-glow"
              >SMS</b-badge
            >
            <b-badge
              v-if="data.item.method == 'PHONE'"
              pill
              variant="info"
              class="badge-glow"
              >PHONE</b-badge
            >
            <b-badge
              v-if="data.item.method == 'EMAIL'"
              pill
              variant="primary"
              class="badge-glow"
              >EMAIL</b-badge
            >
            <b-badge
              v-if="data.item.method == 'LETTER'"
              pill
              variant="warning"
              class="badge-glow"
              >LETTER</b-badge
            >
          </template>

          <template #cell(message)="data">
            <div v-if="data.item.message.length < messageLength">
              {{ data.item.message }}
            </div>
            <div
              v-else
              v-b-tooltip.hover.v-secondary
              :title="data.item.message"
            >
              {{ data.item.message.substring(0, messageLength) + ".." }}
            </div>
          </template>

          <!-- <template #cell(sellerType)="data">
            <b-badge v-if="data.item.sellerType == '1'" pill variant="light-primary" class="badge-glow">Individual</b-badge>
            <b-badge v-if="data.item.sellerType == '2'" pill variant="light-warning" class="badge-glow">Business</b-badge>
          </template> -->

          <!-- Column: Actions -->
          <template #cell(actions)="data" v-if="$Can('lead_access')">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'leads-preview',
                  params: {
                    id: data.item.id,
                    firstName: data.item.firstName,
                    lastName: data.item.lastName,
                    source: data.item.source,
                    phone: data.item.phone,
                    email: data.item.email,
                    message: data.item.message,
                    method: data.item.method,
                  },
                }"
              >
                <feather-icon icon="FileTextIcon" />
                <span class="align-middle ml-50">Details</span>
              </b-dropdown-item>

              <b-dropdown-item
                :to="{ name: 'leads-edit', params: { id: data.item.id } }"
                v-if="$Can('lead_edit')"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                {{ dataMeta.of }} entries</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <a
          class="ml-2 mb-2 mr-2 float-right text-primary"
          v-show="$Can('lead-access')"
          v-if="where == 'leads-list'"
          @click="$router.push({ name: 'junk-leads-list' })"
          >Show junk records</a
        >
        <a
          class="ml-2 mb-2 mr-2 float-right text-primary"
          v-show="$Can('lead-access')"
          v-else
          @click="$router.push({ name: 'leads-list' })"
          >Show active records</a
        >
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { VBTooltip, BTable, BProgress, BBadge } from "bootstrap-vue";
import router from "@/router";
import scopeStoreModule from "./scopeStoreModule";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import Treeselect from "@riophae/vue-treeselect";
import flatPickr from "vue-flatpickr-component";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  components: {
    Treeselect,
    BCardCode,
    BTable,
    BProgress,
    BBadge,
    router,
    store,
    scopeStoreModule,
    ToastificationContent,
    vSelect,
    VBTooltip,
    flatPickr,
  },

  setup() {
    const LEAD_APP_STORE_MODULE_NAME = "scope";
    // Register module
    if (!store.hasModule(LEAD_APP_STORE_MODULE_NAME))
      store.registerModule(LEAD_APP_STORE_MODULE_NAME, scopeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEAD_APP_STORE_MODULE_NAME))
        store.unregisterModule(LEAD_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    const leadOptions = [
      // { text: 'Website Status Selection', value: '' },
      { id: "A", text: "Unassigned", value: "A" },
      { id: "B", text: "Open", value: "B" },
      { id: "C", text: "In Progress", value: "C" },
      { id: "D", text: "Completed", value: "D" },
      { id: "E", text: "Created Quote", value: "E" },
      { id: "P", text: "Lost", value: "P" },
      { id: "F", text: "Win", value: "F" },
    ];

    return {
      config: {
        dateFormat: "d-m-Y",
        mode: "range",
      },
      rangeDate: null,

      where: router.currentRoute.name,
      filterDateStart: null,
      filterDateEnd: null,
      treeStatus: null,
      loading: true,
      sourceOption: null,
      includeJunk: false,
      currentPage: 1,
      leadOptions,
      leadStatus: [],
      perPageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["firstName", "lastName", "email", "phoneNumber"],
      totalRows: 0,
      perPage: localStorage.getItem("leadShow") ?? 10,
      leadHeaders: ["id", "clientId", "projectName", "status"],
      colWidthFactor: null,
      fields: [
        // A virtual column that doesn't exist in items
        "index",
        // A column that needs custom formatting
        { key: "firstName", label: "Name" },
        { key: "lastName", label: "Lastname" },

        "source",
        // A regular column
        "email",
        // A regular column
        "phone",
        "user",
        "status",
        // A virtual column made up from two fields
        "actions",
      ],
      listLeads: [],
      fetchLeads: [],
      messageLength: null,
    };
  },
  computed: {
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  watch: {
    rangeDate: {
      handler: function(val, before) {
        if (val != null) {
          if (val.includes("to")) {
            this.filterDateStart = this.rangeDate.split(" to ")[0];
            this.filterDateEnd = this.rangeDate.split(" to ")[1];

            this.getItems();
          }
        }
      },
    },

    perPage: {
      handler: function(val, before) {
        localStorage.setItem("leadShow", val);
      },
    },
  },

  methods: {
    clearDate() {
      if (this.rangeDate != null && this.rangeDate != "") {
        this.filterDateStart = null;
        this.filterDateEnd = null;
        this.rangeDate = null;

        this.getItems();
      }
    },

    onRowSelected(item) {
      if (this.$Can("lead_access")) {
        router.push({ name: "leads-preview", params: { id: item[0].id } });
      }
    },

    filterTreeSelect() {
      let treeOptionsStatus = [
        {
          id: 100000,
          label: "Status",
          children: [],
        },
      ];
      this.leadStatus.forEach((element, index) => {
        let setStatus = {
          id: element.value ? element.value : index,
          label: element.text,
          value: element.value,
          is: "status",
        };
        treeOptionsStatus[0].children.push(setStatus);
      });
      this.leadStatus = treeOptionsStatus;
      this.loading = false;
    },
    generateReport() {
      this.download = true;
      store
        .dispatch("lead/pdfDownload", {
          start: this.filterDateStart,
          end: this.filterDateEnd,
          statusList: this.sourceOption,
          includeJunk: this.includeJunk,
        })
        .then((response) => {
          if (response.status == 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "Regenwald Lead Report.pdf");
            document.body.appendChild(link);
            link.click();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Successful",
                text: "✔️ Report is created!",
                icon: "ThumbsUpIcon",
                variant: "success",
              },
            });
            this.download = false;
          }
        });
    },

    getItems() {
      store
        .dispatch("scope/fetchScopes", {
          start: this.filterDateStart,
          end: this.filterDateEnd,
        })
        .then((res) => {
          this.leadStatus = [];

          this.listLeads = res.data.scopes;

          this.fetchLeads = res.data.scopes;
          this.loading = false;
          this.totalRows = res.data.scopes.length;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  created() {},
  mounted() {
    this.loading = true;
    this.getItems();
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.bg-insta {
  background-color: #bc2a8d;
}

.bg-facebook {
  background-color: #4267b2;
}

.bg-twitter {
  background-color: #1da1f2;
}

.bg-youtube {
  background-color: #ff0000;
}

.bg-linked {
  background-color: #0077b5;
}

.bg-systems {
  background-color: #000000;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
